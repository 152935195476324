import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

export const socialMediaIcons = [
  { icon: <BsInstagram size="1.5rem" color="#FF009F" />, to: "" },
  {
    icon: <BsYoutube size="1.5rem" color="#FF0000" />,
    to: "https://www.youtube.com",
  },
  {
    icon: <BsFacebook size="1.5rem" color="#1877F2" />,
    to: "https://www.facebook.com",
  },
  {
    icon: <BsTwitter size="1.5rem" color="#0A66C2" />,
    to: "https://www.linkedin.com",
  },
  {
    icon: <FaTiktok size="1.5rem" color="#000000" />,
    to: "https://www.linkedin.com",
  },
  {
    icon: <BsLinkedin size="1.5rem" color="#309EA3" />,
    to: "https://www.linkedin.com",
  },
];
