import {
  Box,
  chakra,
  Container,
  HStack,
  Img,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Wrap,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import logo from "../../assets/logo-transparent-min.png";

import { useLang } from "../../hooks/lang-hook";
import { Link } from "react-router-dom";
import { socialMediaIcons } from "../Data";

const Logo = (props) => {
  const { isAmh } = useLang();
  return (
    <HStack spacing={"2px"} cursor="pointer">
      <Img h="3.5rem" src={logo} />
      <Text color="#319284" fontSize={"20px"}>
        {isAmh ? "ዛሬ ገበያ" : "Zare Gebeya"}
      </Text>
    </HStack>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <a href={href} targe="_blank"></a>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const { isAmh } = useLang();

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Logo />
        <Wrap
          justify="center"
          align="center"
          spacingX={5}
          spacingY={3}
          px="10%"
        >
          <Link to={"/about"}>{isAmh ? "ቤት" : "Home"}</Link>
          <Link to={"/about"}>{isAmh ? "ስለ እኛ" : "About us"}</Link>
          <Link to={"/blogs"}>{isAmh ? "ዜና" : "Articles"}</Link>
          <Link to={"/contact"}>{isAmh ? "አግኙን" : "Contact us"}</Link>
          <Link to={"/pricing"}>{isAmh ? "የዋጋ አሰጣጥ" : "Pricing"}</Link>
        </Wrap>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <HStack spacing={"6px"} fontSize={"16px"}>
            <Text>&copy; {1900 + new Date().getYear()}</Text>{" "}
            <Text>
              <strong> {isAmh ? "ዛሬ ገበያ" : "Zare Gebeya"}</strong>
            </Text>{" "}
            <Text>{isAmh ? "መብቱ በህግ የተጠበቀ ነው" : " All rights reserved"}</Text>
          </HStack>
          <HStack spacing={"20px"}>
            {socialMediaIcons?.map((socialMediaIcon, index) => (
              <a target={"_blank"} href={socialMediaIcon?.to} key={index}>
                <Box cursor={"pointer"}>{socialMediaIcon?.icon}</Box>
              </a>
            ))}
          </HStack>
        </Container>
      </Box>
    </Box>
  );
}
