import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../../globalStyles";
import { MenuButton, MenuItem } from "@chakra-ui/menu";
import { Box } from "@chakra-ui/react";

export const Nav = styled.nav`
  background: ${({ transparentBg }) =>
    transparentBg ? "transparent" : "#FFF"};
  height: 4.2rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  ${"" /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */}
  border: ${({ transparentBg }) => (transparentBg ? "none" : "1px solid grey")};
  @media screen and (max-width: 992px) {
    height: 4rem;
  }
`;

export const NavbarContainer = styled(Container)`
  height: 5rem;
  display: flex;
  justify-content: space-between;
  ${Container}
`;

export const NavStart = styled(Link)`
  color: #fff;
  display: flex;
  justify-self: flex-start;
  text-decoration: none;
  font-size: 2rem;
  align-items: center;
`;

export const NavLogo = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* padding: 0.5rem 1rem; */
  /* @media screen and (max-width: 992px) {
    clear:both;
    min-width: 220px;
    margin: auto;
  }; */
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 15px;
  height: 100%;
  width: 100%;
  outline: none;
`;

// export const NavLogo = styled(Link)`
//   cursor: pointer;
// `;

export const StyledInputIcon = styled(Box)`
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* position: absolute; */
  /* width: 100%;
  margin-bottom: 10px; */
  /* margin-left: 2rem; */
  @media screen and (max-width: 1200px) {
    ${"" /* margin-right: 2rem; */}
  }
  @media screen and (max-width: 1100px) {
    ${"" /* margin-right: 0.2rem; */}
  }
  @media screen and (max-width: 992px) {
    ${"" /* margin-right: 5rem; */}
  } ;
`;

export const StyledIcon = styled.button`
  position: absolute;
  padding: 10px;
  /* min-width: 40px; */
  position: absolute;
  min-width: 2.5rem;
  margin-left: 16rem;
  border: none;
  background: none;
  padding: none;
  /* margin-left: 16.8rem; */
  /* z-index: 300; */
  ${"" /* @media screen and (max-width: 1200px) {
    margin-left: 16rem;
  }
  @media screen and (max-width: 1150px) {
    margin-left: 0.4rem;
  }
  @media screen and (max-width: 992px) {
    margin-left: 0.4rem;
    /* display:none; */
  }
  @media screen and (max-width: 550px) {
    display: none;
  } ;
`;

export const StyledInput = styled.input`
  cursor: text;
  font-size: 1rem;
  width: 20rem;
  height: 46px;
  border-radius: 31px;
  padding: 10px;
  padding-left: 17px;
  /* padding-left: 200px; */
  /* padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")}; */
  background-color: "#727D99";
  @media screen and (max-width: 1200px) {
    ${"" /* width: 17.75rem; */}
    padding-left: 20px;
    width: 18rem;
  }
  @media screen and (max-width: 992px) {
    ${"" /* width: 15.75rem;
    padding-left: 42px; */
  }
  }
  @media screen and (max-width: 550px) {
    display: none;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
  }
`;

// const StyledInput = styled.input`
//     width: 90%;
//     padding: grey;
//     border: 0px;
//     font-size: 12px;
//     &:focus {
//         outline: none;
//         box-shadow: 0px 0px 2px red;
//     }
// `;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 992px) {
    display: block;
    width: 30px;
    height: 30px;
    transform: translate(-100%, 60%);
    cursor: pointer;
    &:hover {
      border: none;
    }
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 992px) {
    z-index: 111111;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 70px;
    left: ${({ click }) => (click ? "-40%" : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #fff;
    /* margin-top: 0px; */
  }
`;

export const NavLinks = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  ${"" /* color: #ffffff; */}
  &:hover {
    opacity: 0.7
      color: #319284;
  }
  &:active {
    /* background: #10b981; */
  }
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 0.45rem;
  }

  @media screen and (max-width: 992px) {
    color: #000;
    text-align: start;
    padding: 0.5rem 2rem 0.8rem 2rem;
    width: 100%;
    display: table;
    font-size: 18px;
    &:hover {
      color: #10b981;
      height: 15px;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;

export const ANavLinks = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.4rem;
  height: 100%;
  font-weight: 400;
  font-size: 12px;
  ${"" /* color: #ffffff; */}
  }
  &:hover {
    color: ${({ adminMode }) => (!adminMode ? "#00953b" : "#FFFFFF")};
  }
  &:active {
    /* background: #10b981; */
  }

  @media screen and (max-width: 992px) {
    color: #000;
    text-align: start;
    padding: 0.5rem 2rem 0.8rem 2rem;
    width: 100%;
    display: table;
    font-size: 15px;

    &:hover {
      color: #10b981;
      height: 15px;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;

export const NavLinksBox = styled(Box)`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.7rem;
  color: ${({ colorTransparent }) => (colorTransparent && "#fff")};

  height: 100%;
  font-weight: 400;
  font-size: 16px;
  ${"" /* color: #ffffff; */}
  &:hover {
    color:  #319284;
  }
  &:active {
    /* background: #10b981; */
  }
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 0.45rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 18px;
    color: #000;
    text-align: start;
    padding: 0.8rem 2rem 0.5rem 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #10b981;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;

export const DropNavLink = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: 400;
  font-size: smaller;
  cursor: pointer;
  ${"" /* color: #ffffff; */}
  &:hover {
    color: ${({ dropDown }) => (dropDown ? "#FFFFFF" : "#f7895d")};
  }
  &:active {
    /* background: #10b981; */
  }

  @media screen and (max-width: 992px) {
    color: #fff;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #10b981;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;

export const MenuDropdown = styled(MenuButton)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: 400;
  font-size: smaller;
  color: #ffffff;
  &:hover {
    /* color: #10b981; */
  }
  &:active {
    /* background: #10b981; */
  }

  @media screen and (max-width: 992px) {
    color: #fff;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #10b981;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;
// MenuItem

export const MenuLinks = styled(MenuItem)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: 400;
  font-size: smaller;
  color: #ffffff;
  &:hover {
    color: #212652;
  }
  &:active {
    background: #212652;
  }

  @media screen and (max-width: 992px) {
    color: #fff;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #10b981;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;

export const NavItem = styled.li`
  height: 55px;
  display: ${({ mobileOnly }) => (mobileOnly ? "none" : true)};
  color: ${({ about }) => (about ? "#FFF" : "#319284")};
  font-size: 13px;
  ${"" /* margin-bottom: 10px; */}
  &:hover {
    ${"" /* border: ${({ dropDown }) => (dropDown ? "none" : "1px solid #d77b5b")}; */
  }
    border-radius: 5px;
  }
  @media screen and (max-width: 992px) {
    height: 45px;
    padding-top: 12px;
    font-size: 18px;
    display: inline;
    }
    ${"" /* left: ${({ click }) => (click ? "-50%" : "-100%")}; */}

    &:hover {
      border: none;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: red;
  }
  &:focus {
    background-color: red;
  }
`;
