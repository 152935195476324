import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ANavLinks, NavItem, NavLinks, NavLinksBox } from "./NavBar.elements";
import { useMediaQuery } from "@chakra-ui/media-query";
import { LangContext } from "../../context/LangContext";
import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/auth-hook";
import { useLang } from "../../hooks/lang-hook";
import UnsubscribeModal from "./UnsubscribeModal";

function StyledNavLinks({ onCancel }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");
  const [isGreaterThan1200] = useMediaQuery("(min-width: 1200px)");
  const { changeLang, isOro, isAmh } = useLang();

  const adminMode = location?.pathname.startsWith("/administration");

  const [button, setButton] = useState(true);

  const { token, logout } = useAuth();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window?.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window?.addEventListener("scroll", handleScroll);

    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  const onLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    showButton();
  }, []);

  window?.addEventListener("resize", showButton);

  const about =
    location?.pathname.toLowerCase() === "/about" ||
    location?.pathname.toLowerCase() === "/about/";

  return (
    <Stack direction={{ base: "column", lg: "row" }} spacing={"2px"}>
      <UnsubscribeModal
        isOpen={isOpen}
        onClose={onClose}
      // onAccept={onAccept}
      />
      {token && (
        <NavItem>
          <NavLinks
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
            to='/'
            cursor='pointer'
          >
            {isOro ? "Mana" : isAmh ? "መነሻ" : "Home"}
          </NavLinks>
        </NavItem>
      )}{" "}
      {token && (
        <NavItem>
          <NavLinks
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
            to='/all-products'
            cursor='pointer'
          >
            {isOro ? "Oomisha" : isAmh ? "ምርቶች" : "Products"}
          </NavLinks>
        </NavItem>
      )}{" "}
      {token && (
        <NavItem>
          <NavLinks
            to='/about'
            cursor='pointer'
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
          >
            {isOro ? "waa'ee" : isAmh ? "ስለ እኛ" : "About"}
          </NavLinks>
        </NavItem>
      )}{" "}
      {token && (
        <NavItem>
          <NavLinks
            to='/contact'
            cursor='pointer'
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
          >
            {isOro ? "Qunnamtii" : isAmh ? "አግኙን" : "Contact"}
          </NavLinks>
        </NavItem>
      )}
      <NavItem>
        <NavLinks
          to='/blogs'
          cursor='pointer'
          style={{
            color:
              about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
          }}
        >
          {isOro ? "Barreeffama" : isAmh ? "ዜና" : "Articles"}
        </NavLinks>
      </NavItem>
      {token && (
        <NavItem>
          <NavLinks
            to='/media/page=1'
            cursor='pointer'
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
          >
            {isOro ? "Miidiyaa" : isAmh ? "ሚዲያ" : "Medias"}
          </NavLinks>
        </NavItem>
      )}
      {isSmallerThan992 && (
        <NavItem>
          <NavLinksBox
            onClick={() => {
              isOro
                ? changeLang(false, false)
                : isAmh
                  ? changeLang(false, true)
                  : changeLang(true, false)
              onCancel();
            }}
            cursor='pointer'
          >
            {isOro ? "English" : isAmh ? "Afaan Oromoo" : "Amharic"}
          </NavLinksBox>
        </NavItem>
      )
      }
      {/* {
        token && (
          <NavItem>
            <NavLinksBox
              onClick={onOpen}
              cursor="pointer"
              colorTransparent={about && scrollPosition < 100 && !isSmallerThan992 && "#FFF"}
            >
              Unsubscribe
            </NavLinksBox>
          </NavItem>
        )
      } */}
      <Flex
        display={{ base: "none", lg: "flex" }}
        alignItems={"center"}
        color='#319284'
      >
        <Menu>
          <MenuButton
            py={2}
            transition='all 0.3s'
            _focus={{ boxShadow: "none" }}
          >
            <HStack spacing={"2px"}>
              <Text
                alignItems='flex-start'
                spacing='1px'
                ml='1'
                bg={"transparent"}
                fontSize='16px'
                display={{ base: "none", lg: "flex" }}

                color={about && scrollPosition < 100 && !isSmallerThan992 && "#FFF"}
              >
                {isOro ? "afaan" : isAmh ? "ቋንቋ" : "Lang"}
              </Text>
              <Box display={{ base: "none", md: "flex" }}>
                <ArrowDownIcon color={about && "#FFF"} />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList
            minW={"70px"}
            bg='#FFF'
            p={0}
            fontSize={"14px"}
            borderColor='#3F1052'
          >
            <MenuItem display={"none"}></MenuItem>
            <MenuItem
              bg='#FFFFFF'
              onClick={() => changeLang(false, false)}
              _active={{ bg: "#31b0b3", color: "#FFF" }}
              _hover={{ bg: "#31b0b3", color: "#FFF" }}
            >
              Eng
            </MenuItem>
            <MenuItem
              bg='#FFFFFF'
              onClick={() => changeLang(true, false)}
              _active={{ bg: "#31b0b3", color: "#FFF" }}
              _hover={{ bg: "#31b0b3", color: "#FFF" }}
            >
              Amh
            </MenuItem>
            <MenuItem
              bg='#FFFFFF'
              onClick={() => changeLang(false, true)}
              _active={{ bg: "#31b0b3", color: "#FFF" }}
              _hover={{ bg: "#31b0b3", color: "#FFF" }}
            >
              Oro
            </MenuItem>
          </MenuList>
        </Menu>{" "}
      </Flex>
      {
        token && (
          <NavItem adminMode={adminMode}>
            <NavLinksBox
              adminMode={adminMode}
              style={{
                color:
                  about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
              }}
              onClick={() => {
                onLogout();
                onCancel();
              }}
            >
              {isOro ? "Sochii Ala" : isAmh ? "ውጣ" : "Log out"}
            </NavLinksBox>
          </NavItem>
        )
      }
      {/* {token && (
        <NavItemBtn>
          <NavBtnLink>
            {button ? (
              <StyledButton logout small onClick={auth.subscriberLogout}>
                LOGOUT
              </StyledButton>
            ) : (
              <StyledButton onClick={auth.subscriberLogout} big fontBig primary>
                LOGOUT
              </StyledButton>
            )}
          </NavBtnLink>
        </NavItemBtn>
      )} */}
    </Stack>
  );
}

export { StyledNavLinks };
