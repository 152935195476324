import { Box, Center, Stack } from "@chakra-ui/react";
import React, { Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useParams,
} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/auth-hook";
import { useLang } from "./hooks/lang-hook";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import ScrollToTop from "./ScrollToTop";
import Footer from "./shared/components/Footer";
import MainNavigation from "./shared/Navigation/MainNavigation";
import { BounceLoader } from "react-spinners";
import { LangContext } from "./context/LangContext";
import AuthRoutes from "./routes/AuthRoutes";

const App = () => {
  const { isAmh, changeLang } = useLang();
  let routes;
  const { id, type, token, login, checked, logout } = useAuth();

  if (token) {
    routes = <AuthRoutes />;
  } else if (checked) {
    routes = <NoAuthRoutes />;
  }

  const location = useLocation();

  const [click, setClick] = useState(false);

  return (
    <Stack
      onClick={(e) => {
        if (click) {
          setClick(false);
        }
      }}
      spacing={
        location?.pathname.toLowerCase() === "/about" ||
          location?.pathname.toLowerCase() === "/about/"
          ? "-70px"
          : "0px"
      }
    >
      <MainNavigation setClick={setClick} click={click} />
      <ScrollToTop />
      <Box bgColor={"#F7FAFC"}>
        <Suspense
          fallback={
            <Center alignSelf={"center"} minH="500px">
              <BounceLoader size={40} color="#319284" />
            </Center>
          }
        >
          {checked ? (
            routes
          ) : (
            <Center my="100px">
              <BounceLoader size={40} color="#319284" />
            </Center>
          )}
        </Suspense>
      </Box>
      <Stack
        spacing={
          location?.pathname.toLowerCase() === "/about" ||
            location?.pathname.toLowerCase() === "/about/"
            ? "60px"
            : "0px"
        }
      >
        <Box />
        <Footer />
      </Stack>
    </Stack>
  );
};

export default App;
