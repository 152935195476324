import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const Login = React.lazy(() => import("../auth/Login"));
const BlogDetail = React.lazy(() => import("../blog/blogDetail/BlogDetail"));
const BlogList = React.lazy(() => import("../blog/blogList/BlogList"));

const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />{" "}
      <Route path="/login" element={<Login />} />{" "}
      <Route path="/blogs" element={<BlogList />} />{" "}
      <Route path="/blogs/:id" element={<BlogDetail />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default NoAuthRoutes;
