import React, { useContext, useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Img,
  Box,
  HStack,
  Text,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Input,
  Heading,
  Stack,
} from "@chakra-ui/react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  MobileIcon,
} from "./NavBar.elements";
import { StyledNavLinks } from "./StyledNavLinks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/media-query";
import logo from "../../assets/logo-transparent-min.png";
import { LangContext } from "../../context/LangContext";
import { SearchIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../context/AuthContext";
import { useLang } from "../../hooks/lang-hook";
import { useAuth } from "../../hooks/auth-hook";

function MainNavigation({ click, setClick }) {
  //on handleKeyDown
  const { token } = useAuth()

  const keyPress = (event) => {
    if (event?.key === "Enter") {
      navigate("/all-products", { state: "" });
      // setSearchValue("");
    }
  };
  const handleClick = (e) => {
    setClick(!click);
  };
  const [isSmallerThan350] = useMediaQuery("(max-width: 350px)");

  const navigate = useNavigate();
  const { changeLang, isOro, isAmh } = useLang();

  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");

  const location = useLocation();

  const adminMode = location?.pathname.startsWith("/administration");
  const btnRef = React.useRef();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window?.addEventListener("scroll", handleScroll);

    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Nav
      transparentBg={
        (location?.pathname.toLowerCase() === "/about" ||
          location?.pathname.toLowerCase() === "/about/") &&
          scrollPosition < 100 &&
          !isSmallerThan992
          ? true
          : false
      }
      adminMode={adminMode}
    >
      <Stack direction="row" align="center" justify="space-between" w="100%" maxW={{ base: "1250px", '2xl': token ? "1250px" : "1600px" }} mx="auto" px={{ base: "0.8rem", md: "1.5rem" }}>
        <Stack direction="row" align="center" spacing={{ base: "1rem", lg: "0.5rem", xl: "1rem" }} pl="5px">
          <NavLogo to="/">
            <HStack spacing={{ base: "-2px", sm: "2px" }}>
              <Img h="3.3rem" src={logo} minW="52px" />
              <Text
                color="#319284"
                display={{ base: "none", md: "flex" }}
                fontSize={{
                  base: "15px",
                  sm: "18px",
                  md: "20px",
                  lg: "16px",
                  xl: "18px",
                }}
              >
                {isOro ? "Arra Daldaluu" : isAmh ? "ዛሬ ገበያ" : "Zare Gebeya"}
              </Text>
            </HStack>
          </NavLogo>
          {
            token &&
            !location?.pathname.toLowerCase().startsWith("/categories/") &&
            !location?.pathname.toLowerCase().startsWith("/all-products") && (
              <InputGroup
                onClick={() => navigate("/all-products", {
                  state: { fromSearch: true },
                })}
                // size={{ base: "lg", sm: "lg" }}
                minW={
                  isSmallerThan350
                    ? "inital"
                    : {
                      base: "180px",
                      sm: "250px",
                      md: "310px",
                      lg: "170px",
                      xl: "260px",
                    }
                }
                maxW={
                  isSmallerThan350
                    ? "initial"
                    : {
                      base: "180px",
                      sm: "250px",
                      md: "310px",
                      lg: "170px",
                      xl: "260px",
                    }
                }
                mr={
                  isSmallerThan350
                    ? "60px"
                    : { base: "80px", sm: "50px", lg: "0px" }
                }
                alignSelf={"center"}
              >
                <Input
                  onChange={(event) => {
                    keyPress(event);
                    // setSearchValue(event.target.value);
                  }}
                  fontSize={{ base: "14px", sm: "initial" }}
                  onKeyDown={keyPress}
                  _active={{ border: "1px solid #32ADAF" }}
                  _focus={{ border: "2px solid #32ADAF" }}
                  placeholder={
                    isOro ? "Maal berbadanii ..." : isAmh ? "ምን ይፈልጋሉ" : "What are you looking for ..."
                  }
                />
                <InputRightAddon
                  onClick={() => navigate("/all-products")}
                  cursor={"pointer"}
                  w={{ base: "50px", sm: "60px" }}
                  borderLeftRadius={"0px"}
                  borderRightRadius={"10px"}
                  bgColor={"#32ADAF"}
                  children={<SearchIcon color="#FFFFFF" />}
                  placeholder="mysite"
                />
              </InputGroup>
            )}
        </Stack>

        <Box
          display={{ base: "flex", lg: "none" }}
          pr="15px"
          onClick={handleClick} ref={btnRef}
        >
          {click ? (
            <Img
              w="30px"
              minW="30px"
              src="https://img.icons8.com/material-outlined/24/000000/delete-sign.png"
            />
          ) : (
            <Img
              w="30px"
              minW="30px"
              src="https://img.icons8.com/material-outlined/24/000000/menu.png"
            />
          )}
        </Box>

        {isSmallerThan992 ? (
          <Drawer
            isOpen={click}
            placement="left"
            onClose={handleClick}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <Box w="100%" mt="10px">
                <Link to="/">
                  <HStack
                    px={"20px"}
                    justifySelf={"center"}
                    alignSelf={"center"}
                    spacing={"2px"}
                  >
                    <Img h="3.5rem" src={logo} />
                    <Heading color="#56A59A" fontSize={"2xl"}>
                      {isOro
                        ? "Arra Daldaluu"
                        : isAmh
                          ? "ዛሬ ገበያ"
                          : "Zare Gebeya"}
                    </Heading>
                  </HStack>
                </Link>
              </Box>
              <StyledNavLinks
                onCancel={handleClick}

              />
            </DrawerContent>
          </Drawer>
        ) : (
          <NavMenu onClick={handleClick} click={click}>
            <StyledNavLinks />
          </NavMenu>
        )}
      </Stack>
    </Nav>
  );
}

export default MainNavigation;
