import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const MediaDescription = React.lazy(() => import("../video/MediaDescription/MediaDescription"));
const Pricing = React.lazy(() => import("../about/components/Pricing"));

const About = React.lazy(() => import("../about/About"));
const Contact = React.lazy(() => import("../contact/Contact"));
const BlogDetail = React.lazy(() => import("../blog/blogDetail/BlogDetail"));
const CatProductsList = React.lazy(() =>
  import("../product/CatProductsList/CatProductsList")
);
const BlogList = React.lazy(() => import("../blog/blogList/BlogList"));

const HomePage = React.lazy(() => import("../Home/HomePage"));
const PrivacyPolicy = React.lazy(() =>
  import("../shared/components/PrivacyPolicy")
);
const VideoList = React.lazy(() =>
  import("../video/VideoList/VideoList")
);

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/all-products" element={<CatProductsList />} />
      <Route path="/blogs" element={<BlogList />} />{" "}
      <Route path="/blogs/:id" element={<BlogDetail />} />
      <Route path="/categories/:categoryId" element={<CatProductsList />} />
      <Route path="/categories/:categoryId/:id" element={<productDetail />} />
      <Route path="/about" element={<About />} />{" "}
      <Route path="/pricing" element={<Pricing />} />{" "}
      <Route path="/contact" element={<Contact />} />
      <Route path="/media/page=:filter" element={<VideoList />} />
      <Route path="/media/:id" element={<MediaDescription />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthRoutes;
