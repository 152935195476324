import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState("");
  const [token, setToken] = useState(false);
  const [checked, setChecked] = useState(false);

  // const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((token, user) => {
    setToken(token);
    setUser(user);
    localStorage.setItem(
      "zareUserData",
      JSON.stringify({
        token,
        user,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("zareUserData");
  }, []);

  let loginData;
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("zareUserData"));
    if (storedData) {
      if (storedData.token) {
        loginData = login(storedData.token, storedData.user);
        setChecked(true);
      }
    }
    setChecked(true);
  }, [loginData]);

  return (
    <AuthContext.Provider value={{ token, user, checked, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
