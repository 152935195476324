import { Center, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Stack, Box } from "@chakra-ui/react";
import React from "react";
import { useLang } from "../../hooks/lang-hook";

const UnsubscribeModal = (props) => {
  const { isOpen, onClose } = props;
  const { isOro, isAmh } = useLang();
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />

      <Stack spacing={"-50px"}>
        <Box />
        <ModalContent p="20px 30px" mb="10px" fontSize="20px">
          <ModalCloseButton />
          <Stack spacing="15px">
            <Center
              fontSize="22px"
              fontWeight="bold"
              style={{ paddingTop: "20px" }}
              pb="5px"
            >
              Unsubscribe
            </Center>
            <Text fontSize="18px" fontWeight="bold">
              {
                isOro ?
                  "Unsubscribe gochuuf stop 8483 erguu qabdu." :
                  isAmh ?
                    "ከደንበኝነት ምዝገባ ለመውጣት ወደ 8483 stop መላክ አለብዎት።" :
                    "In order to unsubscribe you have to send stop to 8483."}
            </Text>
            <a
              href={
                getMobileOperatingSystem() === "iOS"
                  ? "sms:8483&body=stop"
                  : "sms:8483?body=stop"
              }
            >
              <Button
                bgColor="#319284"
                color="#ffffff"
                float="right"
                variant="secondary"
              >
                Send
              </Button>
            </a>
          </Stack>
        </ModalContent>
      </Stack>
    </Modal>
  );
};

export default UnsubscribeModal;
